// eslint-disable-next-line import/no-unresolved
import "virtual:svg-icons-register";

import IMAGE_ASSETS from "@/lib/importImageAssets.js";

const isProduction = process.env.NODE_ENV === "production";

export default function initLrApp(APP = {}) {
  APP.DEBUG = typeof APP.DEBUG !== "undefined" ? APP.DEBUG : !isProduction;
  APP.IMAGE_ASSETS = IMAGE_ASSETS;

  return APP;
}
