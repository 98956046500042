
<svg
  :viewBox="viewBox"
  aria-hidden="true"
  :class="[colorClass]"
  v-bind="$attrs"
>
  <title v-if="title">{{ title }}</title>
  <use :xlink:href="'#icon-' + icon" />
</svg>
