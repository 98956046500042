/* eslint-disable */

const dataLayer = window.dataLayer || [];

const formatTickets = (tickets) => tickets.map((t) => {
    return {
        sku:  t.ticket.id,
        name:  `${t.performance.startDate}-${t.performance.startTime} | ${t.ticket.description} | ${t.event.title}`,// ‘T-Shirt’,
        category: "Ticket" ,
        price:  t.total / t.quantity,
        quantity:  t.quantity,
    };
});
// NB currently using the client side product state as product details missing
// from LU API's order response
const formatProducts = (products) => products.map((p) => {
    return {
        sku: p.lineupId,
        name: p.title,
        category: "Product",
        price: p.price,
        quantity: p.quantity,
    };
});


const googleAnalytics = {
    currentPath: '/',
    debug: process.env.NODE_ENV !== "production",
    setPage(path) {
        this.currentPath = path;
    },
    sendPageView() {
        const event = {
            event: 'pageview',
            page: {
                path: this.currentPath,
            }
        };

        dataLayer.push(event);

        if (this.debug) {
            console.log(`[gtm-debug]`, event);
        }
    },
    sendEvent(event, category, action, label, value, extra = {}) {
        const result = {
            event,
            category,
            action,
            label,
            value,
            ...extra,
        };

        if (this.debug) {
            console.log(`[gtm-debug]`, result);
        }

        dataLayer.push(result);
    },
    sendPurchase({ order, productPurchases }, label, value) {
        this.sendEvent(
            "purchase",
            "Tickets",
            "Payment",
            label,
            value,
            this.formatPurchase(order, productPurchases),
        );
    },
    formatPurchase({
        reference,
        total,
        tax = 0,
        shipping = 0,
        ticketPurchases = [],
    }, productPurchases) {

        return {
          transactionId: reference,
          transactionAffiliation: window.location.hostname,
          transactionTotal: total,
          transactionShipping: shipping,
          transactionTax: tax,
          transactionProducts: [
              ...formatTickets(ticketPurchases),
              ...formatProducts(productPurchases)
          ],
        };
    },
    gaAvailable() {
        return window.ga;
    }
};

export { googleAnalytics as default };