const splitForSeparator = (str) =>
  str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  );

export const toKebabCase = (str) =>
  str &&
  splitForSeparator(str)
    .map((s) => s.toLowerCase())
    .join("-");

export const toSnakeCase = (str) =>
  str &&
  splitForSeparator(str)
    .map((s) => s.toLowerCase())
    .join("_");

// export const toCamelCase = (str) =>
//   str
//     .toLowerCase()
//     .replace(/[^a-zA-Z0-9]+(.)/g, (m, (chr) => chr.toUpperCase()));
/**
 * Export a Vue mixin
 */
export default {
  methods: {
    toKebabCase,
    toSnakeCase,
    // toCamelCase,
  },
};
